import './App.css';
import Landingpage from './Components/Landingpage';
import MenuButton from './Components/MenuButton';

function App() {
  return (
    <div className="">
   <Landingpage/>
    </div>
  );
}

export default App;
