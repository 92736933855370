import React, { useEffect, useRef, useState } from 'react'
import MenuButton from './MenuButton';
import ScrollingTextComponent from './ScrollingTextComponent';
import Warehouse from './Warehouse';
import Imageframes from './Imageframes';
import Videoscrooling from './Videoscrooling';
import Plans from './Plans';
import Footer from './Footer';

import Aos from "aos";
import "aos/dist/aos.css";
import Marquee from 'react-fast-marquee';
function Landingpage() {
// menu
const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const [isOpen, setIsOpen] = useState(false);
const menuRef = useRef(null);
const toggleRef = useRef(null);
const sidebarRef = useRef(null);
const scrollToForm = useRef();
const scrollToExploreRef = useRef();
const scrollTostudio = useRef();
const scrollToshots = useRef();
const scrollTopricing = useRef();
const scrollToservices = useRef();
const scrollToabout = useRef();


const scrvideoRef = useRef(null);

    // vidie play back speed
    const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5; // Set playback speed here
    }
  }, []);  



  React.useEffect(()=>{
    Aos.init({
      duration:1200,
      delay:150,
      easing:"ease-in-out-cubic",
    });
  },[]);
  
  // Scrolls to the top when updates
  // useEffect(() => {
  //   window.scrollTo(0, 0); 
  // }, []);


  // menu button functionality

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  const exploreScrollFun = () => {
    if (scrollToExploreRef.current) {
      scrollToExploreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const aboutScrollFun = () => {
    if (scrollToabout.current) {
      scrollToabout.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getservicesScrollFun = () => {
    if (scrollToservices.current) {
      scrollToservices.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const pricingScrollFun = () => {
    if (scrollTopricing.current) {
      scrollTopricing.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getScrollFun = () => {
    if (scrollToForm.current) {
      scrollToForm.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getstudioScrollFun = () => {
    if (scrollTostudio.current) {
      scrollTostudio.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ShotsScrollFun = () => {
    if (scrollToshots.current) {
      scrollToshots.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // warehouse
  useEffect(() => {
    if (scrvideoRef.current) {
      scrvideoRef.current.playbackRate = 1; // Set playback speed here
    }
  }, []);



  // footer functionality

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    description: ''
  });

  const [errors, setErrors] = useState({});

  const [showToast, setShowToast] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.description) newErrors.description = 'Description is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

 

 
  return (
    <div>

{/* 
         <div className='absolute top-8 right-5 bg-slate-950'>
        <MenuButton />
      </div> */}


{/* manu component */}
<div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

<div className='absolute top-8 right-5 bg-slate-950'>
<button
        className="hidden md:block rounded-2xl relative xl:top-4 xl:right-6 z-30 bg-[#ffffff] hover:bg-menu-hover-gradient w-20 xl:w-28 h-10 font-semibold text-black text-lg tracking-normal"
      >
        MENU
      </button>
      </div> 
     
      <div
        ref={menuRef}
        className={`absolute  top-24 right-10 z-30 xl:w-[400px] h-[620px] xl:h-[560px]  bg-[#000000] shadow-lg rounded-md transform transition-all duration-500 ease-in-out origin-top-left ${
          isOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'
        }`}
      >
        <ul className="p-12 text-center   font-alumni text-white hidden md:block">
          <li onClick={getstudioScrollFun} className="font-bold py-2 text-5xl">OUR STUDIO</li>
          <li onClick={ShotsScrollFun} className="font-bold py-2 text-5xl">SHOOTS</li>
          <li onClick={getScrollFun} className="font-bold py-2 text-5xl">CONTACT US</li>
          <li className="py-2 text-3xl pt-12  ">TIKTOK</li>
          <li className="py-2 text-3xl">YouTube</li>
          {/* <li className="py-2 text-3xl">INSTAGRAM</li> */}
          <li className="py-2 text-3xl">
      <a href="https://www.instagram.com/riddlefilmingrecords/" target="_blank" rel="noopener noreferrer">
        Instagram
      </a>
    </li>
        </ul>
      </div>

      <header className='relative'>
        <nav className='flex items-center justify-between'>

          
         <div className='absolute top-8 right-5 bg-slate-950'>
         <button ref={toggleRef} onClick={toggleSidebar} className="p-4 md:hidden relative z-30">
            <i className="fa fa-bars text-white text-4xl" aria-hidden="true"></i>
          </button>
      </div>
       
{/* small screens menu */}
          <div ref={sidebarRef} className={`md:hidden fixed top-0 right-0 w-46 h-80 py-0 px-0 bg-transparent rounded-xl shadow-md z-50 transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <ul className="p-12 bg-black text-center font-alumni   text-white">
              <li onClick={getstudioScrollFun} className="py-2 text-2xl font-bold">OUR STUDIO</li>
              {/* <li onClick={ShotsScrollFun} className="py-2 text-5xl">SHOOTS</li> */}
              <li onClick={getScrollFun} className="py-2 text-2xl font-bold">CONTACT US</li>
              <li className="py-2 text-xl pt-6">TIKTOK</li>
              <li className="py-2 text-xl">YouTube</li>
              <li className="py-2 text-xl">
      <a href="https://www.instagram.com/riddlefilmingrecords/" target="_blank" rel="noopener noreferrer">
        Instagram
      </a>
    </li>
              {/* <li className="py-2 text-xl"><a href='https://teams.live.com/l/message/19:meeting_YWFlNzYwODktYzcyZC00OWY5LThhZjgtZjE1ZGE3YTkzMjRi@thread.v2/1721717488099?context=%7B%22contextType%22%3A%22chat%22%7D'> </a>INSTAGRAM</li> */}
            </ul>
          </div>
        </nav>
      </header>
    </div>
{/* manu component end */}



<div className="relative w-full h-[600px]  xl:h-screen overflow-hidden ">
      {/* landing Video */}
      <video
        className="absolute top-0 left-0 w-full h-[600px] xl:h-screen  object-fit  object-cover"
        src='images/landingvideo2.mp4'
        autoPlay
        loop
        muted
        ref={videoRef}
      />

{/* angle frame */}
      <div>
      <img className=" absolute  top-28 xl:top-60  xl:left-20 brightness-200 w-20 h-20" alt="La vimeo square" src="images/toplcurve.png" />
      </div> 

      <div>
      <img className=" absolute top-28 xl:top-60 right-1 xl:right-20 brightness-200 w-20 h-20" alt="La vimeo square" src="images/toprcurve.png" />
      </div> 

      <div>
      <img className=" absolute bottom-10 xl:bottom-20 xl:left-20 brightness-200 w-20 h-20" alt="La vimeo square" src="images/bottomlcurve.png" />
      </div> 

      <div>
      <img className=" absolute bottom-10   xl:bottom-20 right-1 xl:right-20 brightness-200 w-20 h-20" alt="La vimeo square" src="images/bottomrcurve.png" />
      </div> 
{/* angle frame ends*/}

      {/* Content */}
      <header data-aos="fade-down" className='relative z-10 justify-around '>
        <div className="flex justify-between xl:px-20 xl:pt-0">
        <div>
        <img className='w-[150px] xl:w-[300px] rounded-full object-fill' src='images/logo2.png'></img>
        </div>
        </div>
      </header>


    </div>

    {/* white bacground text */}
    <div className="md:flex text-center xl:text-left pt-12 xl:pt-32 xl:pb-32 xl:px-32 justify-around items-center">

      <div>
      <p data-aos="fade-down" className=" font-alumni  font-extrabold  text-[#222121] text-5xl xl:text-8xl tracking-[0] md:mb-16 lg:mb-0">
                  WE RENT OUT OUR<br/>
                  CREATIVE STUDIO,<br/>
                  WHICH IS BUILT FOR<br/>
                  CREATIVE FILMING
                  </p> 
      </div>
                
                
                  <div data-aos="fade-up" className='md:w-[40%] 2xl:justify-self-end '>
                  <p className="p-4 font-azeret text-justify  text-black xl:text-2xl tracking-[0] xl:leading-[40px]">
                    We believe that the only way to arouse wonder is to 
                    experience it firsthand. So we turn each video into an immersive experience because we want you to
                    wow as much as we wow.
                  </p>
                  <button onClick={getScrollFun} className=" md:mt-0 mt-5 p-2 rounded-3xl border 2xl:ml-4 border-black 2xl:w-40 md:w-32 md:h-10 2xl:h-12 [font-family:'Lexend',Helvetica] font-semibold  text-black text-lg tracking-[0] leading-[normal]">
                 GET QUOTE</button>
                  </div>
                
                </div>
                
                {/* <Warehouse/> */}

                {/* warehouse component starts */}

                <div ref={scrollToabout} className='flex flex-col xl:flex relative bg-studiobgimg h-screen w-full bg-cover mt-10 bg-no-repeat bg-center md:bg-top'>
  <div data-aos="fade-down" className='absolute top-10 md:left-10 xl:left-20 pt-20 md:pt-0  lg:pt-0 xl:p-4 justify-center '>

  <p className=' md:hidden font-alumni text-[#ffffff] font-bold text-[50px] md:text-[70px] text-center justify-center xl:text-[90px] 3xl:text-[115px] leading-[40px] md:leading-[50px] xl:leading-[70px] 3xl:leading-[100.4px] p-2'>
      Filming Studio Space
      Where You can Innovate
    </p>

    <p className=' hidden md:block  font-alumni text-[#ffffff] font-bold text-[50px] md:text-[70px] text-center justify-center xl:text-[90px] 3xl:text-[115px] leading-[40px] md:leading-[50px] xl:leading-[70px] 3xl:leading-[100.4px]'>
      Filming Studio Space<br />
      Where You can<br /> Innovate
    </p>
  </div>

    {/* <p className=' hidden pt-4 font-azeret text-sm xl:text-xl md:text-left text-white'>
      with cutting-edge technology & Infrastructure<br />
      that unlock, inspire, and nurture the imagination of<br />
      storytellers
    </p>

    <p className='2xl:block pt-4 font-azeret text-justify   text-white'>
      with cutting-edge technology & Infrastructure
      that unlock, inspire, and nurture the imagination of
      storytellers
    </p> */}

  <div className='flex items-center justify-center min-h-screen'>
    <button onClick={getservicesScrollFun} className='mt-10 lg:mt-0 xl:mt-8 text-lg md:text-xl w-[188px] h-[35px] md:h-[43px] bg-black text-white border-2 border-white'>
      EXPLORE STUDIO
    </button>
  </div>

  <div data-aos="fade-up" className='absolute inset-0 flex  justify-center items-center  top-[650px] lg:top-[650px] md:left-[400px] lg:left-[600px] xl:left-[700px] 2xl:left-[1000px] xl:bottom-10'>
    <p className='font-alumni text-white font-bold text-[50px] md:text-[70px] xl:text-[90px] 3xl:text-[115px] 3xl:text-9xl text-center leading-10 md:leading-[55px] xl:leading-[70px]'>
      Create ground<br />
      breaking content
    </p>
  </div>

</div>
  <div ref={scrollToservices}  className='md:flex items-center justify-around 2xl:gap-20 2xl:px-[200px] xl:py-20  bg-black'>

<div data-aos="fade-down" className='flex flex-col gap-0  text-center'>
    <p className="font-alumni font-bold text-[80px] lg:text-[160px] bg-gradient-to-r from-[#F94D4D] via-[#D84343]  to-[#932D2D] text-transparent bg-clip-text ">
     WAREHOUSE<br/>
    </p>
    <span className='text-[80px] lg:text-[160px] font-alumni font-bold leading-[10px] text-[#ffffff]'>STUDIO</span> 

    <p className='font-mono text-2xl xl:w-[800px] text-[#ffffff] mt-20 '>
     Shoot your music videos, commercials at our<br/>
    Studio with 4,000  SFT space & 16ft heigh <br/>ceillings 
    </p>
  </div>


  <video
  data-aos="fade-up"
        className=" w-full md:h-[770px] object-cover"
        src='images/continuesplay.mp4'
        autoPlay
        loop
        muted
        ref={scrvideoRef}
      />
</div>
                {/* warehouse component end */}


                {/* <Imageframes/> */}

                {/* imageframes component starts */}
                <div ref={scrollTostudio} className=' bg-imageframesbg   bg-cover bg-center bg-no-repeat '>
<div data-aos="fade-down" className=' hidden xl:block'>
  <p className=' text-center text-white  font-alumni font-bold text-5xl xl:text-8xl pt-10'>Change makers in visual craft and <br/>
  creative solutions</p>
  <p className='text-white text-center text-lg xl:text-xl  pt-0'>
    Looking for one stop solutions to unleash your creativity?<br/>
    As Filming Studio space providers,we offer a range of Custom creative solutions that cater to content<br/>
    creators accross the media and entertainment fraternity.
  </p>
</div>

<div className=' xl:hidden '>
  <p className=' text-center text-white font-bold font-alumni text-5xl xl:text-6xl pt-10'>Change makers in visual craft and <br/>
  creative solutions</p>
  <p className='text-white text-center text-lg xl:text-xl md:px-10 pt-10'>
    Looking for one stop solutions to unleash your creativity?
    As Filming Studio space providers,we offer a range of Custom creative solutions that cater to content
    creators accross the media and entertainment fraternity.
  </p>
</div>  


<div  data-aos="fade-up" className='flex flex-col justify-center items-center gap-6 pt-20'>

  <div className='flex flex-col md:flex-row justify-center  gap-4  xl:gap-8 md:px-2'>

    <div className="relative group">
      <p className="text-4xl text-white font-bold font-alumni text-center absolute inset-0 flex items-center justify-center z-10">Production</p>
      <img src="images/production.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>

    <div className="relative group">
      <p className="text-4xl text-white font-alumni font-bold text-center absolute inset-0 flex items-center justify-center z-10">Editing Room</p>
      <img src="images/editing.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>

    <div className="relative group">
      <p className="text-4xl text-white font-bold font-alumni text-center absolute inset-0 flex items-center justify-center z-10">Virtual Production</p>
      <img src="images/virtual.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>
    
  </div>

  <div className='flex flex-col md:flex-row justify-center gap-4 xl:gap-8 md:px-2'>
    <div className="relative group">
      <p className="text-4xl text-white font-alumni font-bold text-center absolute inset-0 flex items-center justify-center z-10">Shooting Floors</p>
      <img src="images/floors.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>

    <div className="relative group">
      <p className="text-4xl text-white font-alumni font-bold text-center absolute inset-0 flex items-center justify-center z-10">Dressing Room</p>
      <img src="images/dressing.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>

    <div className="relative group">
      <p className="text-4xl text-white font-alumni font-bold text-center absolute inset-0 flex items-center justify-center z-10">Resting Area</p>
      <img src="images/resting.png" alt="Production" className="rounded-xl xl:h-[210px] xl:w-[370px] transition-transform duration-300 ease-in-out transform group-hover:scale-110" />
    </div>
  </div>


  
</div>


</div>



{/* vertical images */}
<div className=" lg:flex  pt-20 justify-center xl:gap-64 bg-framesbgimg  bg-black bg-no-repeat md:pt-20 bg-cover  items-center ">
    <div data-aos="fade-down" className=' items-center'>
      <h1 className=' text-4xl md:text-5xl xl:text-4xl 2xl:text-6xl font-alumni font-extrabold text-center md:text-start  text-white text-transparent bg-clip-text md:px-20 xl:px-2'>
      EXCLUSIVE CUSTOM SETS <br/>
      YOU COULD BUILD
      </h1>
      
    <ul className="md:mt-10 font-extrabold text-white custom-list  justify-between pb-20">
{/* <li className='text-6xl  font-alumni font-extrabold text-center  text-white text-transparent bg-clip-text'>
EXCLUSIVE CUSTOM SETS <br/>
YOU COULD BUILD
</li> */}
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1 pt-10 md:pt-0 bg-gradient-to-r from-[#E0E0E0]  to-[#66625E]  font-bold text-transparent bg-clip-text"> Pool Shots</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Colored Beams</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Mirrors</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Photo-shoots</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Horror Sets</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Stand up Comedy</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Rain Themes</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">  Fire Machines</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Car Shoots</li>
    <li className="text-2xl md:text-4xl xl:text-2xl 2xl:text-4xl pl-16 2xl:pl-1  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">Podcasts</li>
    {/* <li className="text-4xl pl-16  bg-gradient-to-r from-[#E0E0E0]  to-[#66625E] font-bold text-transparent bg-clip-text mt-2">+ much more</li> */}
</ul>
    </div>

    <div ref={scrollToshots} className=' hidden md:flex gap-2 font-bold text-xl '>
  <div className='flex flex-col gap-2'>
    <div className='relative group'>
      <img src="images/shoot.png" alt="" className="rounded-xl w-[11rem] h-[23rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Photo Shoots</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot1.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Shoots</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot2.png" alt="" className="rounded-xl w-[11rem] h-[20rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Dancing Auditorium</span>
      </div>
    </div>
  </div>

  <div className='flex flex-col gap-2'>
    <div className='relative group'>
      <img src="images/shoot3.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Podcasts</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot4.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Stand up Comedy</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot5.png" alt="" className="rounded-xl w-[11rem] h-[23rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Colored Beams</span>
      </div>
    </div>
  </div>

  <div className='flex flex-col gap-2'>
    <div className='relative group'>
      <img src="images/shoot6.png" alt="" className="rounded-xl w-[11rem] h-[23rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Car Shoots</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot7.png" alt="" className="rounded-xl w-[11rem] h-[23rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Horror Sets</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot8.png" alt="" className="rounded-xl w-[11rem] h-[20rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Talks</span>
      </div>
    </div>
  </div>

  <div className='flex flex-col gap-2'>
    <div className='relative group'>
      <img src="images/shoot9.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Fire Machines</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot10.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Rain Themes</span>
      </div>
    </div>
    <div className='relative group'>
      <img src="images/shoot11.png" alt="" className="rounded-xl w-[11rem] h-[22rem] object-cover flex-shrink-0" />
      <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300'>
        <span className='text-white text-center'>Pool Shots</span>
      </div>
    </div>
  </div>
  
</div>


</div>
                {/* imageframes component ends */}

              {/* <Videoscrooling/> */}

                {/* video component starts */}
                <div>

<div data-aos="fade-down" className=' text-center pt-20'>
    <button className=' rounded-[30px] text-sm xl:text-xl p-1 xl:px-4 xl:py-2 bg-gradient-to-b from-[#929292]   to-[#B4AEAE] '>Over thousand of successfully completed projects</button>

    <p className=' text-5xl xl:text-6xl font-bold font-alumni pt-6'>The one shop destination for all<br/>
    your project shoots</p>
    <p className=' text-xl pt-6'>Get Inspired by the work of hundreds of content creators & film directors around the world</p>
    <button onClick={getScrollFun} className=' text-xl rounded-[20px] font-azerto  w-[172px] h-[54px]  text-center  mt-8 bg-black text-white'>Get started</button>
</div>

<Marquee speed={200}  className="marquee-container">
<div className='flex gap-4 pt-20 p-4'>
{/* video 1 */}
<div className="w-[400px] h-[400px] rounded-2xl overflow-hidden">
<video
className="w-full h-full object-cover"
src='images/automotive1.mp4'
autoPlay
loop
muted
ref={videoRef}
/>
</div>

<div className='w-[400px] h-[400px]'>
<img className="w-full h-full object-cover rounded-2xl" src="images/scrimg.png" alt="marquee" />
</div>

{/* video 2 */}
<div className="w-[400px] object-fill h-[400px] rounded-2xl  overflow-hidden">
<video
className="w-full h-full object-cover"
src='images/vid1.mp4'
autoPlay
loop
muted
ref={videoRef}
/>
</div>

<div className='w-[400px] h-[400px]'>
<img className="w-full h-full object-cover rounded-2xl" src="images/marimg.png" alt="marquee" />
</div>

{/* video 3 */}
<div className="w-[400px] h-[400px] rounded-2xl overflow-hidden">
<video
className="w-full h-full object-cover"
src='images/perfume1.mp4'
autoPlay
loop
muted
ref={videoRef}
/>
</div>

<div className='w-[400px] h-[400px]'>
<img className="w-full h-full object-cover rounded-2xl" src="images/scrimg4.png" alt="marquee" />
</div>
</div>
</Marquee>

</div>

                {/* imageframes component ends */}


{/* <Plans/> */}
                {/* imageframes component starts */}

                <div className='bg-black '>

<div className='bg-missionbgimg mt-10 text-white   object-cover bg-black bg-center bg-no-repeat xl:px-40 py-28'>
<div data-aos="fade-down" className='flex md:justify-end justify-center'>
  <div className='md:text-left text-center'>
    <p className=' text-5xl md:text-6xl text-center xl:text-left xl:text-8xl font-bold font-alumni'>Our mission</p>
    <p className='text-xl text-center md: font-thin'>
      To provide the creative heads with the<br />
      infrastructure they need, so <br /> 
      that they could get their ideas to reality
    </p>
  </div>
</div>

<div data-aos="fade-up" className='flex pt-12 justify-center md:justify-start'>
  <div className=' text-center xl:text-left'>
  <p className='text-5xl md:text-6xl xl:text-8xl font-bold font-alumni'>Our vision</p>
    <p className='text-xl font-thin text-center'>
      To be one of the most liked and
      filmed <br />studio in and  around GTA
    </p>
  </div>
</div>
</div>

{/* plans */}
<div ref={scrollTopricing}>

<div   className=' hidden lg:block bg-black  text-center xl:pt-20'>
    <p className=' text-4xl lg:text-6xl font-extrabold font-alumni text-white'>
        We are the most affordable when it comes<br/>
        to renting our space</p>

        <p className=' text-xl text-white'>
            Book your slot by paying a portion as deposit and pay the rest on the shoot day
        </p>
</div>

<div  className='lg:hidden bg-black  text-center lg:pt-20'>
    <p className=' text-4xl lg:text-6xl font-extrabold font-alumni text-white'>
        We are the most affordable when it comes
        to renting our space</p>

        <p className=' text-xl text-white'>
            Book your slot by paying a portion as deposit and pay the rest on the shoot day
        </p>
</div>

<div className='flex flex-col lg:flex-row justify-center gap-8 lg:gap-10 pt-20 items-center'>
    <div className='bg-black text-[#ffffff] text-center'>
        <p className='text-5xl xl:text-7xl font-extrabold font-alumni'>Book the studio Now!</p>
        <p className='text-lg pt-6 md:hidden '>
            You can now book the studio either by filling out the
         form above or You may Contact us by the below ways
         and discuss about Pricing Discounts.
        </p>

        <p className='text-xl pt-6  hidden md:block'>
            You can now book the studio either by filling out the<br/>
         form above or You may Contact us by the below ways<br/>
         and discuss about Pricing Discounts.
        </p>

        <div className='flex gap-3 lg:gap-10 justify-center py-8'>
        <a href="https://www.instagram.com/riddlefilmingrecords/" target="_blank" rel="noopener noreferrer">
  <button className='font-merri bg-gradient-to-b from-[#FF4085] to-[#E9CD97] text-black lg:px-4 py-2 rounded-xl font-bold w-20 lg:w-28'>
    Instagram
  </button>
</a>

            <button className='font-merri bg-gradient-to-b from-[#654DA9] to-[#71CDEB] text-black px-4 py-2 rounded-xl font-bold w-20 lg:w-28'>
                TikTok
            </button>
            <button className='font-merri bg-[#FBFBFB] text-black px-4 py-2 rounded-xl font-bold w-20 xl:w-28'>
                Email
            </button>
        </div>
    </div>
    {/* card1 */}
    <div  data-aos="fade-down" className='flex flex-col gap-2 w-[310px] xl:w-[360px] h-[450px] items-center justify-center text-white border-2 border-[#FFFFFF] p-5 text-center rounded-[20px] bg-white bg-opacity-0 backdrop-blur-md backdrop-opacity-50'>
        <p className='text-2xl font-bold font-merri'>Standard Plan</p>
        <p className='text-4xl font-bold font-merri'>
            $75<span className='text-xl font-azeret text-[#7B6F6F]'>/hour</span>
        </p>

        <p className='text-center text-xl text-[#BEBEBE] font-merri font-thin pt-0'>
            4 Hours Minimum
        </p>
        <button onClick={getScrollFun} className='border border-[#888888] rounded-lg text-white w-32 p-2'>
            Get started
        </button>
        <p className='text-[#BAB6B6] text-xl'>
            Book day rates to save money
            <br /> per hour.
        </p>
        <p className='text-[#BAB6B6] text-xl'>
            Studio comes with 1-2 Studio
            <br /> Assistants.
        </p>
        <p className='text-[#BAB6B6] text-xl'>Sets Not included</p>
    </div>

    {/* card2 */}
    <div data-aos="fade-up" className='flex flex-col gap-6 w-[310px] xl:w-[360px] lg:mb-40 h-[450px] items-center justify-center text-white border-2 border-[#FFFFFF] p-5 text-center rounded-[20px] bg-white bg-opacity-0 backdrop-blur-md backdrop-opacity-50'>
        <p className='text-3xl font-bold font-merri'>Custom Plan</p>

        <button onClick={getScrollFun} className='border border-[#888888] rounded-lg text-white w-32 p-2'>
            Contact us
        </button>
        <p className='text-[#BAB6B6] text-xl'>All pre-lit options included</p>
        <p className='text-[#BAB6B6] text-xl'>
            Access to additional DIT &
            <br /> Producer room
        </p>
        <p className='text-[#BAB6B6] text-xl'>
            Cover your time which you
            <br /> spend on building sets
        </p>
    </div>
</div>

    </div>

                {/* imageframes component ends */}


{/* <Footer/> */}
                {/* imageframes component starts */}

                <div ref={scrollToForm} className='bg-black'>

<div className='lg:flex pt-10 xl:gap-32 bg-black justify-center items-center  xl:p-20'>

<div  data-aos="fade-up"  className=' relative'>

{/* className="relative   xl:h-[650px] 2xl:h-[800px]" */}
{/* director imgs  */}
  <div className='flex flex-col md:flex-row gap-2  justify-center items-center '>

  <div>
  <img
    className="w-[280px] h-[350px] xl:w-[300px] xl:h-[400px] 2xl:w-[400px] md:h-[380px] 2xl:h-[550px] object-fill border-2  border-zinc-200 rounded-tl-[110px] rounded-tr-[10px] "
    src="images/marimg.png"
    alt="Contact Us"
  />
</div>


<div className='md:mt-60'>
  <img
    className="w-[280px] h-[350px] xl:w-[300px] xl:h-[400px] 2xl:w-[400px] md:h-[380px] 2xl:h-[550px] object-fill border-2  border-zinc-200 rounded-br-[110px] rounded-bl-[10px] "
   src="images/formtopimg.jpg"
    alt="Contact Us"
  />
</div>
  </div>



<div className="absolute bottom-0  p-4 w-full md:px-20 xl:px-0">
{/* <p className=" text-2xl md:text-4xl font-bold text-white">Contact Us</p>
<p className="md:text-xl text-left text-white pt-1 md:pt-4 ">
  Ask about our studio, pricing,
  add-ons, or anything else. Our highly
  trained reps are standing by. Ready to help.
</p> */}
</div>
</div>


{/* form */}
<div  data-aos="fade-down" className='bg-black flex justify-center items-center min-h-screen'>
<div className="bg-black xl:p-8 p-4 rounded-lg shadow-lg w-full max-w-xl">
{/* <h2 className="text-2xl font-bold text-center text-white">Contact Us</h2> */}
<p className=" text-4xl md:text-6xl font-bold text-white font-alumni text-center">Contact Us</p>
<p className="md:text-xl text-center text-white pt-1 md:pt-4 ">
  Ask about our studio, pricing,
  add-ons, or anything else. Our highly
  trained reps are standing by. Ready to help.
</p>
<form onSubmit={handleSubmit} className="space-y-6 pt-10">
<div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
<div className="flex-1">
  <label className="block text-sm font-medium text-white">First Name</label>
  <input
    type="text"
    name="firstName"
    value={formData.firstName}
    onChange={handleChange}
    className={`mt-3 p-2 w-full h-[45px] bg-[#2F2A2A] ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-[10px]`}
  />
  {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
</div>
<div className="flex-1">
  <label className="block text-sm font-medium text-white">Last Name</label>
  <input
    type="text"
    name="lastName"
    value={formData.lastName}
    onChange={handleChange}
    className={`mt-3 p-2 w-full h-[45px] bg-[#2F2A2A] ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-[10px]`}
  />
  {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
</div>
</div>
<div>
<label className="block text-sm font-medium text-white">Phone Number</label>
<input
  type="text"
  name="phone"
  value={formData.phone}
  onChange={handleChange}
  className={`mt-3 p-2 w-full h-[45px] bg-[#2F2A2A] ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-[10px]`}
/>
{errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
</div>
<div>
<label className="block text-sm font-medium text-white">Email Address</label>
<input
  type="email"
  name="email"
  value={formData.email}
  onChange={handleChange}
  className={`mt-3 p-2 w-full h-[45px] bg-[#2F2A2A] ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-[10px]`}
/>
{errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
</div>
<div>
<label className="block text-sm font-medium text-white">Description</label>
<textarea
  name="description"
  value={formData.description}
  onChange={handleChange}
  className={`mt-3 p-2 w-full h-[120px] bg-[#2F2A2A] ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-[10px]`}
></textarea>
{errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
</div>
<button type="submit" className="w-full h-[54px] bg-[#4865FF] text-white py-2 rounded-md hover:bg-blue-600">
Submit
</button>
</form>
{showToast && (
<div className="mt-4 p-4 bg-green-500 text-white text-center rounded">
Our team will reach out to you soon!
</div>
)}
</div>
</div>

</div>



</div>

{/* footer */}

<div className='bg-black bg-footerbgimg bg-cover bg-no-repeat md:flex md:flex-col justify-around'>

<div className='flex flex-col lg:flex-row justify-around items-center xl:p-10 p-5'>
<div className='flex flex-col text-white  xl:mb-0'>
<img className='w-[400px] xl:w-[400px] rounded-full object-fill mx-auto' src='images/logo2.png' alt='Logo'></img>
</div>

<div className='text-white font-sans font-thin flex flex-col gap-3 xl:gap-6 text-xl md:text-2xl xl:text-3xl mb-6 xl:mb-0'>
<button onClick={getservicesScrollFun} className='text-center font-alumni'>Services</button>
<button onClick={aboutScrollFun} className='text-center font-alumni'>About Us</button>
<button onClick={getScrollFun} className='text-center font-alumni'>Contact Us</button>
<button onClick={pricingScrollFun}  className='text-center font-alumni'>Pricing</button>
</div>

<div className='flex flex-col text-white text-center'>
<p className='font-bold font-serif text-3xl'>Follow Us</p>
<div className="h-px w-full xl:w-[350px] self-stretch bg-white mt-4" />
<div className='flex gap-4 pt-2 justify-center items-center'>
<img className="w-10 h-10" alt="YouTube" src="images/yout.png" />
<a href="https://www.instagram.com/riddlefilmingrecords/" target="_blank" rel="noopener noreferrer">
  <img className="w-12 h-12" alt="Instagram" src="images/inst.png" />
</a>
<img className="w-12 h-12" alt="Vimeo" src="images/vimo.png" />
<img className="w-12 h-12" alt="TikTok" src="images/tiktok.png" />
</div>
</div>
</div>

<div className='flex flex-col lg:flex-row gap-0 md:gap-4 justify-around  md:items-center  xl:px-20 text-white p-5 '>
<div className='flex gap-2  items-center mb-4 lg:mb-0'>
<img className="w-8 h-8" alt="map" src="images/maplogo.png" />
<p className='xl:text-xl text-left xl:text-center'>Unit 22, 1040 Martin Grove Dr, Etobicoke, ON Canada</p>
</div>

<div className='flex gap-2 items-center mb-4 lg:mb-0'>
<img className="w-8 h-8" alt="email" src="images/emaillogo.png" />
<p className='xl:text-xl text-center'>info@riddlefilmingrecords.com</p>
</div>

<div className='flex gap-2 items-center'>
<img className="w-8 h-8" alt="call" src="images/phone.png" />
<p className='xl:text-xl text-center'>+1 647 787 6733</p>
</div>
</div>

</div>



<div className='lg:px-20 bg-black pt-2'>
<div className="h-px  w-full self-stretch  bg-white  " />
<p className=' text-sm text-center p-2 text-white  font-roboto'>Powered by Alif Dynamics Inc. 2024</p>
</div>
</div>
    </div>
  )
}

export default Landingpage